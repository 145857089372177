import {
  HStack,
  Icon,
  IconButton,
  Td,
  Tr,
  useDisclosure,
} from '@chakra-ui/react'
import React from 'react'
import { BiSolidEdit, BiTrashAlt } from 'react-icons/bi'
import { MdOutlineRemoveRedEye } from 'react-icons/md'
import { useAuth } from '../../../hooks/useAuth'
import { formatClasse } from '../../../utils/formatClasse'
import EditarCredores from '../editar-credor'

function CardCredor({ credor, updateCredor, deleteCredor }) {
  const { isAdmin, callId } = useAuth()
  const { isOpen, onClose, onOpen } = useDisclosure()

  const toCurrency = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value / 100)
  }

  return (
    <Tr>
      <Td>{credor?.nome}</Td>
      <Td>{credor.documento}</Td>
      <Td>{formatClasse(credor.agc_classe_classe)}</Td>
      <Td isNumeric>{credor.moeda}</Td>
      <Td isNumeric>{toCurrency(credor.valor)}</Td>
      <Td isNumeric>
        <HStack justify='end'>
          <IconButton
            display={isAdmin ? 'block' : 'none'}
            aria-label=''
            onClick={onOpen}
            icon={<Icon as={BiSolidEdit} fontSize='22px' color='primary' />}
          />

          <IconButton
            display={isAdmin ? 'block' : 'none'}
            onClick={() => deleteCredor(credor.id)}
            aria-label=''
            icon={<Icon as={BiTrashAlt} fontSize='22px' color='primary' />}
          />

          <IconButton
            onClick={() => {
              const newRoute = `agc/${callId}/credor/${credor.id}`
              window.open(`https://novo.e-agc.com.br/${newRoute}`, '_blank')
            }}
            aria-label=''
            icon={
              <Icon
                as={MdOutlineRemoveRedEye}
                fontSize='22px'
                color='primary'
              />
            }
          />

          {isAdmin && (
            <EditarCredores
              updateCredor={updateCredor}
              isOpen={isOpen}
              onClose={onClose}
              credor={credor}
            />
          )}
        </HStack>
      </Td>
    </Tr>
  )
}

export default CardCredor
